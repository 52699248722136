import React from 'react';
import {
  Accordion,
  AccordionItem,
  DirectionAndPlacement,
  Header,
  IconName,
  Size,
  Style,
  templatedString,
} from '@pointdotcom/pds';
import { dayjs } from 'lib/dayjs';
import DashboardModel from 'models/DashboardModel';
import {
  TimelineCompletedStage,
  TimelineInProgressStage,
  TimelineItemStatus,
  TimelineNotStartedStage,
  TimelineStageId,
} from 'services/apiTypes/homeownerTypes';
import i18n from './i18n';
import {
  FundingTimelineSectionStyle,
  InnerItemHeaderStyle,
  ItemHeaderStyle,
  StatusDotStyle,
  StatusPillStyle,
  TimelineAccordionStyle,
  TimelineItemStyle,
  TimelineStageSectionStyle,
  TimelineStageWrapperStyle,
} from './styles';

export function getEstimatedTimeDescription(
  stage: TimelineNotStartedStage | TimelineInProgressStage
) {
  // All InProgress and NotStarted stages should have a duration, this default is just a fallback for bad data
  if (!stage.duration) {
    return i18n.estimatedOneWeek;
  }

  const { minDays, maxDays } = stage.duration;

  if (stage.id === TimelineStageId.ApplyForFundingStage) {
    return i18n.estimated15Minutes;
  }
  if (maxDays < 7) {
    return i18n.estimatedLess;
  }
  if (maxDays === 7) {
    return i18n.estimatedOneWeek;
  }

  // Show time range in weeks
  const minWeeks = Math.max(1, Math.floor(minDays / 7));
  const maxWeeks = Math.ceil(maxDays / 7);
  return templatedString({
    values: { minWeeks, maxWeeks },
    template: i18n.estimatedMultipleWeeks,
  });
}

interface FundingTimelineSectionProps {
  dashboard: DashboardModel;
}

interface TimelineItemProps {
  stage: TimelineCompletedStage | TimelineInProgressStage | TimelineNotStartedStage;
}
const TimelineItem = ({ stage }: TimelineItemProps) => {
  const stepStatusTextMap = {
    [TimelineItemStatus.Completed]: i18n.complete,
    [TimelineItemStatus.InProgress]: i18n.inProgress,
    [TimelineItemStatus.NotStarted]: i18n.notStarted,
  };

  const { status, title, description } = stage;
  let subTitle = '';

  if (status === TimelineItemStatus.Completed) {
    const completionDate = stage.completionDate;
    const completionDateFormatted = dayjs(completionDate).format('MMM D');
    subTitle = templatedString({
      values: { date: completionDateFormatted },
      template: i18n.completedOnDate,
    });
  } else if (status === TimelineItemStatus.InProgress || status === TimelineItemStatus.NotStarted) {
    subTitle = getEstimatedTimeDescription(stage);
  }

  return (
    <TimelineItemStyle
      isDefaultExpanded={status === TimelineItemStatus.InProgress}
      status={status}
      title={
        <ItemHeaderStyle>
          <StatusDotStyle name={IconName.Check} />
          <span>{title}</span>
          <span>{subTitle}</span>
        </ItemHeaderStyle>
      }
      iconType={IconName.ChevronDown}
      iconStyleAlign={DirectionAndPlacement.Right}
    >
      <aside>{description}</aside>
      <Accordion>
        {stage.steps.map((step) => (
          <AccordionItem
            key={step.id}
            title={
              <InnerItemHeaderStyle>
                <span>{step.title}</span>
                <StatusPillStyle status={step.status}>
                  {stepStatusTextMap[step.status]}
                </StatusPillStyle>
              </InnerItemHeaderStyle>
            }
            content={step.description}
            iconType={IconName.ChevronDown}
            iconStyleAlign={DirectionAndPlacement.Right}
          />
        ))}
      </Accordion>
    </TimelineItemStyle>
  );
};

interface TimelineStageSectionProps {
  status: TimelineItemStatus;
  dashboard: DashboardModel;
}

const TimelineStageSection = ({ dashboard, status }: TimelineStageSectionProps) => {
  const statusTitleMap = {
    [TimelineItemStatus.Completed]: i18n.completed,
    [TimelineItemStatus.InProgress]: i18n.currentStage,
    [TimelineItemStatus.NotStarted]: i18n.upcoming,
  };

  const { stages } = dashboard.getTimeline();
  const stagesFromStatus = stages.filter((stage) => stage.status === status);
  if (!stagesFromStatus.length) {
    return null;
  }

  return (
    <TimelineStageSectionStyle>
      <Header styleType={Style.Uppercase} styleSize={Size.Small}>
        <StatusDotStyle name={IconName.Check} />
        {statusTitleMap[status]}
      </Header>
      <TimelineAccordionStyle>
        {stagesFromStatus.map((stage) => (
          <TimelineItem stage={stage} key={stage.id} />
        ))}
      </TimelineAccordionStyle>
    </TimelineStageSectionStyle>
  );
};

export function FundingTimelineSection({ dashboard }: FundingTimelineSectionProps) {
  return (
    <FundingTimelineSectionStyle headerText={i18n.yourFundingTimeline}>
      <TimelineStageWrapperStyle>
        <TimelineStageSection dashboard={dashboard} status={TimelineItemStatus.Completed} />
        <TimelineStageSection dashboard={dashboard} status={TimelineItemStatus.InProgress} />
        <TimelineStageSection dashboard={dashboard} status={TimelineItemStatus.NotStarted} />
      </TimelineStageWrapperStyle>
    </FundingTimelineSectionStyle>
  );
}
