import { defineFeatureFlag } from './featureFlagDefintionHelpers';

// Set in console like so - document.cookie = 'm2_dashboard=true';

export const posthogFeatureFlagDefinitions = {
  _test_flag: defineFeatureFlag('__test_flag__', ['first_value', 'other_value']),
  ApplicationTaskCompleteModal: defineFeatureFlag('application_task_complete_modal', [
    'control',
    'modal',
  ]),
  PrequalContact: defineFeatureFlag('cr10b-pqflow-contact', ['control', 'test']),
  ApplicationCompleteAnimation: defineFeatureFlag('cr18-app-completion-animation', [
    'control',
    'test',
  ]),
  DashboardAmChat: defineFeatureFlag('dashboard-am-chat', ['control', 'test']),
  ShowFileUploadPreviews: defineFeatureFlag('show-file-upload-previews', [false, true]),
  TaskSubmitFileVerification: defineFeatureFlag('task-submit-file-verification', [false, true]),
  DashboardTimeline: defineFeatureFlag('dashboard-timeline', ['disabled', 'enabled']),
  HomeValueConfirmDescribe: defineFeatureFlag('overstated-home-value', ['control', 'test']),
  DashboardTasksPageRevised: defineFeatureFlag('dashboard-tasks-page-revised', ['control', 'test']),
  Cro32ScenariosTableHomeValue: defineFeatureFlag('cro32-scenarios-table-home-value', [
    'control',
    'test',
  ]),
  DocVerifyEOIAndPayoff: defineFeatureFlag('doc_verify_eoi_payoff', [false, true]),
};
